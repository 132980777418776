/* Footer */
.footer {
    background: var(--primary);
    color: #ffffff;
    height: 40rem;
    width: 100%;
    margin-top: 0rem;
}

.footer--container {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: 1fr 1fr 1fr;

    max-width: 150rem;

    margin: 0rem 15% 0rem 15%;
}

/* UbiTheraPlay */
.footer--ubitheraplay {
    grid-column: 1 / 3;
    grid-row: 1 / 2;

    margin-top: 6rem;
}

.footer--ubitheraplay--link {
    font-weight: normal;
    font-size: 3.6rem;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
}

/* Nav Links */
.footer--nav-links {
    grid-column: 1 / 2;
    grid-row: 2 / 3;

    margin-top: 1rem;
}

.footer--nav-links--ul {
    list-style: none;
}

.footer--nav-links--link {
    font-weight: normal;
    font-size: 1.8rem;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
}

.footer--nav-links--p {
    font-weight: normal;
    font-size: 1.8rem;
}

/* UbiTech */
.footer--ubitech {
    grid-column: 4 / 6;
    grid-row: 1 / 4;

    margin-top: 4rem;
    padding-top: 0rem;
}

.footer--ubitech-logo {
    display: block;
    margin-left: auto;
    width: 140%;
}

.footer--ubitech-address {
    grid-column: 3 / 4;
    grid-row: 2 / 4;

    margin: 1rem 8rem 0rem 4rem;
}

.footer--ubitech-address--p {
    font-weight: normal;
    font-size: 1.8rem;
    color: #ffffff;
}


/* Copyright */
.footer--copyright {
    grid-column: 1 / 6;
    grid-row: 3 / 4;

    text-align: center;
    font-weight: normal;
    font-size: 1.8rem;

    margin-top: 6rem;
}



@media screen and (max-width: 960px) {

    .footer--container {
        grid-template-columns: 1fr 1fr 3fr 1fr 1fr;
        margin: 0rem 10% 0rem 10%;
    }

    .footer--ubitech-logo {
        margin-top: 2rem;
        width: 100%;
    }

    .footer--ubitech-address {
        margin: 1rem 1rem 0rem 0rem;
    }
}



@media screen and (max-width: 680px) {
        
    .footer--container {
        display: grid;
        justify-content: center;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;

        margin: 0rem 5% 0rem 5%;
    }

    /* UbiTheraPlay */
    .footer--ubitheraplay {
        grid-column: 1 / 2;
        grid-row: 1 / 2;

        margin-top: 6rem;
    }

    .footer--ubitheraplay--link {
        font-weight: normal;
        font-size: 3.6rem;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
    }

    /* Nav Links */
    .footer--nav-links {
        grid-column: 1 / 2;
        grid-row: 2 / 3;

        margin-top: 2rem;
    }

    .footer--nav-links--ul {
        list-style: none;
    }

    .footer--nav-links--link {
        font-weight: normal;
        font-size: 1.8rem;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
    }

    .footer--nav-links--p {
        font-weight: normal;
        font-size: 1.8rem;
    }

    /* UbiTech */
    .footer--ubitech {
        grid-column: 2 / 3;
        grid-row: 1 / 2;

        margin-top: 1rem;
        padding-top: 0rem;
    }

    .footer--ubitech-logo {
        display: block;
        margin-left: none;
        width: 60%;
    }

    .footer--ubitech-address {
        grid-column: 2 / 3;
        grid-row: 2 / 3;

        margin: 2rem 0rem 0rem 0rem;
    }

    .footer--ubitech-address--p {
        font-weight: normal;
        font-size: 1.8rem;
        color: #ffffff;
    }


    /* Copyright */
    .footer--copyright {
        grid-column: 1 / 3;
        grid-row: 3 / 4;

        text-align: center;
        font-weight: normal;
        font-size: 1.8rem;

        margin-top: 6rem;
    }

}



@media screen and (max-width: 500px) {

    /* Footer */
    .footer {
        height: 36rem;
    }
        
    .footer--ubitheraplay--link {
        font-weight: normal;
        font-size: 3rem;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
    }

    .footer--nav-links--link {
        font-size: 1.6rem;
    }
    
    .footer--nav-links--p {
        font-size: 1.6rem;
    }

    .footer--ubitech-logo {
        width: 40%;
        margin-top: 3.5rem;
    }

    .footer--ubitech-address--p {
        font-size: 1.6rem;
    }

    /* Copyright */
    .footer--copyright {
        font-size: 1.6rem;
        margin-top: 6rem;
    }

}



@media screen and (max-width: 400px) {

    .footer--ubitheraplay--link {
        font-weight: normal;
        font-size: 2.4rem;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
    }

    .footer--nav-links--link {
        font-size: 1.4rem;
    }
    
    .footer--nav-links--p {
        font-size: 1.4rem;
    }

    .footer--ubitech-logo {
        width: 40%;
        margin-top: 3.5rem;
    }

    .footer--ubitech-address--p {
        font-size: 1.4rem;
    }

    /* Copyright */
    .footer--copyright {
        font-size: 1.4rem;
        margin-top: 6rem;
    }

}
