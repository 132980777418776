* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Baloo 2', sans-serif;
    font-size: 62.5%; /* default: 16px; 16px x 62.5% = 10px = 1rem */
}

:root {
    --primary: #2596FF;
}

.flexbox-basic {
    display: flex;
    justify-content: center;
    align-items: center;
}
