.navbar {
    background: #ffffff;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    position: sticky;
    top: 0;
    z-index: 999;

    /* border: 0.2rem solid var(--primary); */
}

.navbar-shadow {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.25);
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    max-width: 150rem;
}

.navbar-logo-link {
    display: flex;
    align-items: center;
    justify-self: start;
    margin-left: 3rem;
    cursor: pointer;
    text-decoration: none;
}

.navbar-logo-image {
    padding-top: 20%;
    width: 6rem;
    background-image: url('../assets/Logo.svg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position-x: left;
    background-position-y: center;
}

.navbar-logo-text {
    color: #353535;
    font-size: 3rem;
}

.navbar-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 1rem;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}

.navbar-item {
    height: 6rem;
}

.navbar-links {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.fa-bars.navbar-bars-icon {
    color: #353535;
}

.navbar-menu-icon {
    display: none;
}



@media screen and (max-width: 960px) {

    .navbar {
        position: relative;
    }

    .navbar-shadow {
        box-shadow: none;
    }

    .navbar-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 6rem;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .navbar-menu.active {
        background: #ffffff;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .navbar-links {
        display: block;
        text-align: center;
        margin: 2rem auto;
        width: 40%;
        padding-top: 0rem;
        transition: all 0.3s ease-out;
    }

    .navbar-logo-link {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .navbar-menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-150%, 20%);
        font-size: 4rem;
        cursor: pointer;
    }

    .fa-times.navbar-times-icon {
        color: #353535;
        font-size: 2.8rem;
    }

}



@media screen and (max-width: 680px) {

    .navbar-logo-link {
        transform: translate(15%, 75%);
    }

    .navbar-logo-image {
        width: 4.5rem;
    }
    
    .navbar-logo-text {
        font-size: 2.5rem;
    }

}



@media screen and (max-width: 400px) {

    .navbar-links {
        display: block;
        text-align: center;
        margin: 2rem auto;
        width: 40%;
        padding-top: 1.4rem;
        transition: all 0.3s ease-out;
    }

    .navbar-menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-130%, 45%);
        font-size: 3.2rem;
        cursor: pointer;
    }

    .fa-times.navbar-times-icon {
        color: #353535;
        font-size: 2.2rem;
    }

}




@media screen 
    and (max-width: 960px)
    and (max-height: 500px)
    and (orientation: landscape) {

    .navbar-item {
        height: 3rem;
    }

    .navbar-links {
        display: block;
        text-align: center;
        margin: 2rem auto;
        width: 40%;
        padding-top: 0rem;
        transition: all 0.3s ease-out;
    }

}
