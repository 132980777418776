
/* Software Section */
.software {
    display: flex;
    justify-content: center;
    align-items: center;
}

.software--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    max-width: 150rem;

    margin: 8rem 15% 0rem 15%;
}

.software--title {
    font-weight: normal;
    font-size: 3.6rem;
    color: #353535;
    text-align: center;
}

.software--subtitle {
    font-size: 2.4rem;
    color: #656565;
    text-align: center;

    margin-top: 1rem;
}

.software--paragraph {
    font-size: 1.8rem;
    color: #353535;
    text-align: center;

    margin-top: 4rem;
}


/* Games Section */
.games {
    display: block;
    background-image: url('../assets/Landing-Games-Background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: top;
    padding-bottom: 20rem;
}

.games--outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.games--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 150rem;
    margin: 0rem 15% 0rem 15%;
}

.games--title {
    font-weight: normal;
    font-size: 3.6rem;
    color: #353535;
    text-align: center;
    margin-top: 10rem;
}

.games--subtitle {
    font-size: 2.4rem;
    color: #656565;
    text-align: center;
    margin-top: 1rem;
}

.games--background {
    margin-top: 12rem;
    background-color: #ffffff;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 100%;
}

.games--game-1 {
    grid-column: 1;
    grid-row: 1 / 2;
    margin-top: -2rem;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 2.5fr;
    width: 100%;
}

.games--game-1--card {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
}

.games--game-1--title {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    justify-content: center;
    align-content: center;
    font-weight: normal;
    font-size: 2.8rem;
    color: #353535;
    text-align: center;
    margin-top: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
}

.games--game-1--description {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    align-self: flex-start;
    font-weight: normal;
    font-size: 1.8rem;
    color: #353535;
    text-align: center;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 0rem;
}

.games--game-2 {
    grid-column: 1;
    grid-row: 2 / 3;
    margin-top: 0rem;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 2.5fr;
    width: 100%;
}

.games--game-2--card {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
}

.games--game-2--title {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    justify-content: center;
    align-content: center;
    font-weight: normal;
    font-size: 2.8rem;
    color: #353535;
    text-align: center;
    margin-top: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
}

.games--game-2--description {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    align-self: flex-start;
    font-weight: normal;
    font-size: 1.8rem;
    color: #353535;
    text-align: center;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 0rem;
}

.games--game-3 {
    grid-column: 1;
    grid-row: 3 / 4;
    margin-top: 0rem;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 2.5fr;
    width: 100%;
}

.games--game-3--card {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
}

.games--game-3--title {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    justify-content: center;
    align-content: center;
    font-weight: normal;
    font-size: 2.8rem;
    color: #353535;
    text-align: center;
    margin-top: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
}

.games--game-3--description {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    align-self: flex-start;
    font-weight: normal;
    font-size: 1.8rem;
    color: #353535;
    text-align: center;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 0rem;
}


@media screen and (max-width: 960px) {

    
    /* Software Section */
    .software--container {
        margin: 8rem 10% 0rem 10%;
    }

    .software--title {
        font-size: 3rem;
    }

    .software--subtitle {
        font-size: 2rem;
        margin-top: 1rem;
    }

    .software--paragraph {
        font-size: 1.6rem;
        margin-top: 3rem;
    }


    /* Games Section */
    .games--title {
        font-size: 3rem;
        margin-top: 8rem;
    }
    
    .games--subtitle {
        font-size: 1.8rem;
    }

    .games--game-1--card {
        margin-top: 0.5rem;
    }

    .games--game-1--title {
        font-size: 2.4rem;
        margin-top: 5rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }
    
    .games--game-1--description {
        font-size: 1.6rem;
        margin-top: 0rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .games--game-2 {
        margin-top: -2rem;
    }
    
    .games--game-2--card {
        margin-top: 2rem;
    }
    
    .games--game-2--title {
        font-size: 2.4rem;
        margin-top: 5rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }
    
    .games--game-2--description {
        font-size: 1.6rem;
        margin-top: 0rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }
    
    .games--game-3 {
        margin-top: -2rem;
    }
    
    .games--game-3--card {
        margin-top: 2rem;
    }
    
    .games--game-3--title {
        font-size: 2.4rem;
        margin-top: 5rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }
    
    .games--game-3--description {
        font-size: 1.6rem;
        margin-top: 0rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }

}


@media screen and (max-width: 820px) {

    .games {
        background-image: url('../assets/Landing-Games-Background-Tablet.svg');
    }

    .games--container {
        margin: 0rem 10% 0rem 10%;
    }

}


@media screen and (max-width: 680px) {

    /* Software Section */
    .software--container {
        margin: 4rem 15% 0rem 15%;
    }

    /* Games Section */
    .games {
        background-image: url('../assets/Landing-Games-Background-Mobile.svg');
    }

    .games--container {
        margin: 0rem 6% 0rem 6%;
    }

    .games--title {
        margin-top: 8rem;
    }

    .games--background {
        margin-top: 6rem;
    }

    .games--game-1 {
        margin-top: 1rem;
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 0.2fr 1fr;
        width: 100%;
    }

    .games--game-1--card {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        margin-top: 0rem;
    }

    .games--game-1--title {
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        margin-top: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .games--game-1--description {
        grid-column: 1 / 4;
        grid-row: 3 / 4;
        align-self: flex-start;
        margin-top: 0rem;
        margin-left: 3rem;
        margin-right: 3rem;
    }

    .games--game-2 {
        margin-top: 0rem;
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 0.2fr 1fr;
        width: 100%;
    }

    .games--game-2--card {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        margin-top: 0rem;
    }

    .games--game-2--title {
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        margin-top: 0rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .games--game-2--description {
        grid-column: 1 / 4;
        grid-row: 3 / 4;
        align-self: flex-start;
        margin-top: 0rem;
        margin-left: 3rem;
        margin-right: 3rem;
    }

    .games--game-3 {
        margin-top: 0rem;
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 0.2fr 1fr;
        width: 100%;
    }

    .games--game-3--card {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        margin-top: 0rem;
    }

    .games--game-3--title {
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        margin-top: 0rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .games--game-3--description {
        grid-column: 1 / 4;
        grid-row: 3 / 4;
        align-self: flex-start;
        margin-top: 0rem;
        margin-left: 3rem;
        margin-right: 3rem;
    }

}
