/* Intro Section */
.landing-intro--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    max-width: 150rem;

    margin: 0rem 20% 20rem 20%;
}

.landing-intro--title {
    font-weight: normal;
    font-size: 3.6rem;
    color: #353535;
    text-align: center;
}

.landing-intro--description {
    font-size: 1.8rem;
    color: #353535;
    margin-top: 4rem;
    text-align: center;
}

.landing-intro--card-section {
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 2fr 0.5fr 1fr;

    width: 100%;

    grid-column-gap: 5%;

    margin: 8rem 0rem 0rem 0rem;
}

.landing-intro--card-section--card-1 {
    grid-column: 1;
    grid-row: 1;
}

.landing-intro--card-section--title-1 {
    grid-column: 1;
    grid-row: 2;

    font-weight: normal;
    font-size: 2.4rem;
    color: #353535;
    text-align: center;

    margin-top: 2rem;
}

.landing-intro--card-section--description-1 {
    grid-column: 1;
    grid-row: 3;

    font-weight: normal;
    font-size: 1.8rem;
    color: #353535;
    text-align: center;

    margin-top: 0.5rem;
}

.landing-intro--card-section--card-2 {
    grid-column: 2;
    grid-row: 1;
}

.landing-intro--card-section--title-2 {
    grid-column: 2;
    grid-row: 2;

    font-weight: normal;
    font-size: 2.4rem;
    color: #353535;
    text-align: center;

    margin-top: 2rem;
}

.landing-intro--card-section--description-2 {
    grid-column: 2;
    grid-row: 3;

    font-weight: normal;
    font-size: 1.8rem;
    color: #353535;
    text-align: center;

    margin-top: 0.5rem;
}

.landing-intro--card-section--card-3 {
    grid-column: 3;
    grid-row: 1;
}

.landing-intro--card-section--title-3 {
    grid-column: 3;
    grid-row: 2;

    font-weight: normal;
    font-size: 2.4rem;
    color: #353535;
    text-align: center;

    margin-top: 2rem;
}

.landing-intro--card-section--description-3 {
    grid-column: 3;
    grid-row: 3;
    align-self: flex-start;

    font-weight: normal;
    font-size: 1.8rem;
    color: #353535;
    text-align: center;

    margin-top: 0.5rem;
}


/* Description Section */
.landing-description--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    max-width: 150rem;

    margin: 0rem 20% 20rem 20%;
}

.landing-description--title {
    font-weight: normal;
    font-size: 3.6rem;
    color: #353535;
    text-align: center;
}

.landing-description--subtitle {
    font-size: 2.4rem;
    color: #656565;
    text-align: center;

    margin-top: 1rem;
}

.landing-description--paragraph-1 {
    font-size: 1.8rem;
    color: #353535;
    text-align: center;

    margin-top: 4rem;
}

.landing-description--paragraph-2 {
    font-size: 1.8rem;
    color: #353535;
    text-align: center;

    margin-top: 4rem;
}

.landing-description--tooltip {
    position: relative;
    display: inline-block;
    font-size: 1.8rem;

    text-decoration: none;
    vertical-align: super;
    font-size: larger;
}

.landing-description--tooltip-a {
    text-decoration: none;
    color: #353535;
    font-size: 1.8rem;
}

.landing-description--tooltip-text{
    visibility: hidden;
    width: 40rem;
    background-color: #555;
    font-size: 1.4rem;
    color: #fff;
    text-align: center;
    padding: 0.8rem 0.5rem;
    border-radius: 0.5rem;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -20rem;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.landing-description--tooltip:hover .landing-description--tooltip-text {
    visibility: visible;
    opacity: 1;
}


/* Packages Section */
.landing-packages--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    max-width: 150rem;

    margin: 0rem 20% 20rem 20%;
}

.landing-packages--title {
    font-weight: normal;
    font-size: 3.6rem;
    color: #353535;
    text-align: center;
    margin: 0rem 0rem 4rem 0rem;
}

.landing-packages--grid {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;

    width: 100%;

    padding: 2rem 0rem 4rem 0rem;

    border: 0.1rem solid #999999;
    border-radius: 0.5rem;
}

.landing-packages--grid--title {
    font-weight: normal;
    font-size: 2.4rem;
    color: #353535;
    text-align: center;

    padding: 2rem 0rem 2rem 0rem;
}

.landing-packages--grid--p {
    font-weight: normal;
    font-size: 2rem;
    color: #555555;
    text-align: center;

    padding: 1rem 6rem;
    margin-bottom: 1rem;
}

.landing-packages--grid--1-1 {
    grid-column: 1;
    grid-row: 1;
}

.landing-packages--grid--1-2 {
    grid-column: 1;
    grid-row: 2;

    margin-bottom: 4rem;
}

.landing-packages--grid--1-3 {
    grid-column: 1;
    grid-row: 3;

    border-right: 0.1rem solid var(--primary);
}

.landing-packages--grid--2-1 {
    grid-column: 2;
    grid-row: 1;
}

.landing-packages--grid--2-2 {
    grid-column: 2;
    grid-row: 2;

    margin-bottom: 4rem;
}

.landing-packages--grid--2-3 {
    grid-column: 2;
    grid-row: 3;
}

.landing-packages--button {
    margin-top: 6rem;
}


/* Software Section */
.landing-games--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    max-width: 150rem;

    margin: 0rem 20% 20rem 20%;
}

.landing-games--title {
    font-weight: normal;
    font-size: 3.6rem;
    color: #353535;
    text-align: center;
}

.landing-games--subtitle {
    font-size: 2.4rem;
    color: #656565;
    text-align: center;

    margin-top: 1rem;
}

.landing-games--paragraph-1 {
    font-size: 1.8rem;
    color: #353535;
    text-align: center;

    margin-top: 4rem;
}

.landing-games--paragraph-2 {
    font-size: 1.8rem;
    color: #353535;
    text-align: center;

    margin-top: 2rem;
}

.landing-games--button {
    margin-top: 6rem;
}


/* News Section */
.landing-news--hidden {
    display: none;
}

.landing-news--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 150rem;
    margin: 0rem 15% 20rem 15%;
}

.landing-news--title {
    font-weight: normal;
    font-size: 3.6rem;
    color: #353535;
    text-align: center;
}

.landing-news--subtitle {
    font-size: 2.4rem;
    color: #656565;
    text-align: center;

    margin-top: 1rem;
}

.landing-news--card-section {
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;

    grid-column-gap: 5%;

    margin-top: 6rem;
}

.landing-news--button {
    margin: 6rem 0rem 0rem 0rem;
}


/* Partners Section */
.landing-partners--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    max-width: 150rem;

    margin: 0rem 20% 24rem 20%;
}

.landing-partners--title {
    font-weight: normal;
    font-size: 3.6rem;
    color: #353535;
    text-align: center;
}

.landing-partners--subtitle {
    font-size: 2.4rem;
    color: #656565;
    text-align: center;

    margin-top: 1rem;
}

.landing-partners--partner-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    margin-top: 1rem;

    padding: 2rem;
}

.landing-partners--partner-list-image-1 {
    background-color: #858585;
    margin: 2rem 2rem;
}

.landing-partners--partner-list-image-2 {
    background-color: #ffffff;
    margin: 2rem 2rem;
}

.landing-partners--partner-list-image-3 {
    background-color: #d6d6d6;
    margin: 2rem 2rem;
}

.landing-partners--partner-list-image-4 {
    background-color: #ffffff;
    margin: 2rem 2rem;
}

.landing-partners--partner-list-image-5 {
    background-color: #ffffff;
    margin: 2rem 2rem;
}

.landing-partners--paragraph {
    font-size: 1.8rem;
    color: #353535;
    text-align: center;

    margin-top: 4rem;
}

.landing-partners--ubitech {
    margin: 0rem;
}

.landing-partners--ubitech-logo {
    background-color: #ffffff;
    display: block;
    margin: 2rem auto 0rem auto;
    width: 100%;
}

.landing-partners--button {
    margin: 2rem 0rem 0rem 0rem;
}


/* Contact Section */
.landing-contact--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 150rem;
    margin: 0rem 25% 26rem 25%;
}

.landing-contact--title {
    font-weight: normal;
    font-size: 3.6rem;
    color: #353535;
    text-align: center;
}

.landing-contact--subtitle {
    font-size: 2.4rem;
    color: #656565;
    text-align: center;

    margin-top: 1rem;
}

.landing-contact--button {
    margin: 6rem 0rem 0rem 0rem;
}

@media screen and (max-width: 960px) {

    /* Intro Section */
    .landing-intro--container {   
        margin: -8rem 15% 8rem 15%;
    }

    .landing-intro--title {
        font-size: 2.8rem;
    }
    
    .landing-intro--description {
        font-size: 1.6rem;
        margin-top: 2.5rem;
    }

    .landing-intro--card-section {
        grid-template-rows: 1fr 0.25fr 1fr;
    }

    .landing-intro--card-section--title-1 {
        font-size: 2.2rem;
    }

    .landing-intro--card-section--description-1 {
        font-size: 1.6rem;
    }

    .landing-intro--card-section--title-2 {
        font-size: 2.2rem;
    }

    .landing-intro--card-section--description-2 {
        font-size: 1.6rem;
    }

    .landing-intro--card-section--title-3 {
        font-size: 2.2rem;
    }

    .landing-intro--card-section--description-3 {
        font-size: 1.6rem;
    }

    
    /* Description Section */
    .landing-description--container {
        margin: 0rem 15% 16rem 15%;
    }

    .landing-description--title {
        font-size: 2.8rem;
    }

    .landing-description--subtitle {
        font-size: 2rem;
    }

    .landing-description--paragraph-1 {
        font-size: 1.6rem;
        margin-top: 2.5rem;
    }

    .landing-description--paragraph-2 {
        font-size: 1.6rem;
        margin-top: 2.5rem;
    }

    .landing-description--tooltip {
        position: static;
        font-size: 1.6rem;
    }

    .landing-description--tooltip-a {
        font-size: 1.6rem;
    }

    .landing-description--tooltip-text{
        width: 30rem;
        font-size: 1.2rem;
        padding: 0.6rem 0.3rem;
        margin-left: -15rem;
    }

        
    /* Packages Section */
    .landing-packages--container {
        margin: 0rem 15% 16rem 15%;
    }

    .landing-packages--title {
        font-size: 2.8rem;
        margin: 0rem 0rem 2.5rem 0rem;
    }

    .landing-packages--grid {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto auto auto;
        padding: 2rem 0rem 2rem 0rem;
    }

    .landing-packages--grid--title {
        font-size: 2.2rem;
    }

    .landing-packages--grid--p {
        font-size: 1.6rem;
        padding: 1rem 2rem;
        margin-bottom: 2rem;
    }

    .landing-packages--grid--1-2 {
        margin-bottom: 2rem;
    }

    .landing-packages--grid--1-3 {
        border-right: none;
        margin-bottom: 4rem;
    }

    .landing-packages--grid--2-1 {
        grid-column: 1;
        grid-row: 4;

        border-top: 0.1rem solid var(--primary);
    }

    .landing-packages--grid--2-2 {
        grid-column: 1;
        grid-row: 5;
        margin-bottom: 2rem;
    }

    .landing-packages--grid--2-3 {
        grid-column: 1;
        grid-row: 6;
    }

    .landing-packages--button {
        margin-top: 4rem;
    }


    /* Software Section */
    .landing-games--container {
        margin: 0rem 15% 16rem 15%;
    }

    .landing-games--title {
        font-size: 2.8rem;
    }

    .landing-games--subtitle {
        font-size: 2rem;
        margin-top: 0.5rem;
    }

    .landing-games--paragraph-1 {
        font-size: 1.6rem;
        margin-top: 3rem;
    }

    .landing-games--paragraph-2 {
        font-size: 1.6rem;
        margin-top: 1.5rem;
    }

    .landing-games--button {
        margin-top: 4rem;
    }


    /* News Section */
    .landing-news--container {
        margin: 0rem 10% 16rem 10%;
    }

    .landing-news--title {
        font-size: 2.8rem;
    }

    .landing-news--subtitle {
        font-size: 2rem;
        margin-top: 0.5rem;
    }

    .landing-news--card-section {
        grid-column-gap: 2.5%;
        margin-top: 4rem;
    }

    .landing-news--button {
        margin: 4rem 0rem;
    }


    /* Partners Section */
    .landing-partners--container {
        margin: 0rem 10% 20rem 10%;
    }

    .landing-partners--title {
        font-size: 2.8rem;
    }

    .landing-partners--subtitle {
        font-size: 2rem;
        margin-top: 0.5rem;
    }

    .landing-partners--partner-list {
        margin-top: 0rem;
        padding: 1rem;
    }

    .landing-partners--partner-list-image-1 {
        margin: 2rem 1rem;
    }

    .landing-partners--partner-list-image-2 {
        margin: 2rem 1rem;
    }

    .landing-partners--partner-list-image-3 {
        background-color: #d6d6d6;
        margin: 2rem 1rem;
    }

    .landing-partners--partner-list-image-4 {
        margin: 2rem 1rem;
    }

    .landing-partners--partner-list-image-5 {
        margin: 2rem 1rem;
    }

    .landing-partners--paragraph {
        font-size: 1.6rem;
        margin-top: 2rem;
    }

    .landing-partners--ubitech-logo {
        width: 80%;
    }


    /* Contact Section */
    .landing-contact--container {
        margin: 0rem 20% 12rem 20%;
    }

    .landing-contact--title {
        font-size: 2.8rem;
    }

    .landing-contact--subtitle {
        font-size: 2rem;
        margin-top: 1.5rem;
    }

    .landing-contact--button {
        margin: 4rem 0rem;
    }

}

@media screen and (max-width: 820px) {

    /* News Section */
    .landing-news--container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin: 0rem 15% 20rem 15%;
    }
    
    .landing-news--title {
        font-weight: normal;
        font-size: 2.8rem;
        color: #353535;
        text-align: center;
    
        margin-top: 4rem;
    }
    
    .landing-news--subtitle {
        font-size: 1.8rem;
        color: #656565;
        text-align: center;
    
        margin-top: 1rem;
    }
    
    .landing-news--card-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        margin-top: 4rem;
    }
    
    .landing-news--button {
        margin: 2rem 0rem 0rem 0rem;
    }
    
    .landing-contact {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    
    /* Partners Section */
    .landing-partners--container {
        margin: 0rem 5% 20rem 5%;
    }

    .landing-partners--title {
        font-size: 2.8rem;
    }

    .landing-partners--subtitle {
        font-size: 2rem;
        margin-top: 0.5rem;
    }

    .landing-partners--partner-list {
        margin-top: 0rem;
        padding: 1rem;
    }

    .landing-partners--partner-list-image-1 {
        margin: 2rem 1rem;
    }

    .landing-partners--partner-list-image-2 {
        margin: 2rem 1rem;
    }

    .landing-partners--partner-list-image-3 {
        background-color: #d6d6d6;
        margin: 2rem 1rem;
    }

    .landing-partners--partner-list-image-4 {
        margin: 2rem 1rem;
    }

    .landing-partners--partner-list-image-5 {
        margin: 2rem 1rem;
    }

    .landing-partners--paragraph {
        font-size: 1.6rem;
        margin-top: 2rem;
    }

    .landing-partners--ubitech-logo {
        width: 80%;
    }
    
}



@media screen and (max-width: 680px) {

    /* Intro Section */
    .landing-intro--container {   
        margin: -12rem 10% 16rem 10%;
    }

    .landing-intro--description {
        font-size: 1.8rem;
        margin-top: 2.5rem;
    }

    .landing-intro--card-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 100%;
        margin: 8rem 0rem 0rem 0rem;
    }

    .landing-intro--card-section--title-1 {
        margin-top: 2rem;
    }

    .landing-intro--card-section--description-1 {
        font-size: 1.8rem;
        margin-top: 0.5rem;
    }

    .landing-intro--card-section--card-2 {
        margin-top: 8rem;
    }

    .landing-intro--card-section--title-2 {
        margin-top: 2rem;
    }

    .landing-intro--card-section--description-2 {
        font-size: 1.8rem;
        margin-top: 0.5rem;
    }

    .landing-intro--card-section--card-3 {
        margin-top: 8rem;
    }

    .landing-intro--card-section--title-3 {
        margin-top: 2rem;
    }

    .landing-intro--card-section--description-3 {
        font-size: 1.8rem;
        margin-top: 0.5rem;
    }

     
    /* Description Section */
    .landing-description--container {
        margin: 0rem 10% 16rem 10%;
    }

    .landing-description--paragraph-1 {
        font-size: 1.8rem;
    }

    .landing-description--paragraph-2 {
        font-size: 1.8rem;
    }

    .landing-description--tooltip {
        font-size: 1.6rem;
    }

    .landing-description--tooltip-a {
        font-size: 1.6rem;
    }

    .landing-description--tooltip:hover .landing-description--tooltip-text {
        visibility: hidden;
    }

        
    /* Packages Section */
    .landing-packages--container {
        margin: 0rem 10% 16rem 10%;
    }

    .landing-packages--grid--p {
        font-size: 1.8rem;
    }


    /* Software Section */
    .landing-games--container {
        margin: 0rem 10% 16rem 10%;
    }

    .landing-games--title {
        font-size: 2.8rem;
    }

    .landing-games--paragraph-1 {
        font-size: 1.8rem;
    }

    .landing-games--paragraph-2 {
        font-size: 1.8rem;
    }


    /* Partners Section */
    .landing-partners--partner-list {
        margin-top: 2rem;
    }

    .landing-partners--paragraph {
        font-size: 1.8rem;
    }


    /* Contact Section */
    .landing-contact--container {
        margin: 0rem 15% 12rem 15%;
    }

}



@media screen and (max-width: 500px) {

    /* Intro Section */
    .landing-intro--container {   
        margin: -12rem 10% 16rem 10%;
    }

    .landing-intro--description {
        font-size: 1.8rem;
        margin-top: 2.5rem;
    }

    .landing-intro--card-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 100%;
        margin: 8rem 0rem 0rem 0rem;
    }

    .landing-intro--card-section--title-1 {
        margin-top: 2rem;
    }

    .landing-intro--card-section--description-1 {
        font-size: 1.8rem;
        margin-top: 0.5rem;
    }

    .landing-intro--card-section--card-2 {
        margin-top: 8rem;
    }

    .landing-intro--card-section--title-2 {
        margin-top: 2rem;
    }

    .landing-intro--card-section--description-2 {
        font-size: 1.8rem;
        margin-top: 0.5rem;
    }

    .landing-intro--card-section--card-3 {
        margin-top: 8rem;
    }

    .landing-intro--card-section--title-3 {
        margin-top: 2rem;
    }

    .landing-intro--card-section--description-3 {
        font-size: 1.8rem;
        margin-top: 0.5rem;
    }

     
    /* Description Section */
    .landing-description--container {
        margin: 0rem 10% 16rem 10%;
    }

    .landing-description--paragraph-1 {
        font-size: 1.8rem;
    }

    .landing-description--paragraph-2 {
        font-size: 1.8rem;
    }

    .landing-description--tooltip {
        font-size: 1.6rem;
    }

    .landing-description--tooltip-a {
        font-size: 1.6rem;
    }

    .landing-description--tooltip:hover .landing-description--tooltip-text {
        visibility: hidden;
    }

        
    /* Packages Section */
    .landing-packages--container {
        margin: 0rem 10% 16rem 10%;
    }

    .landing-packages--grid--p {
        font-size: 1.8rem;
    }


    /* Software Section */
    .landing-games--container {
        margin: 0rem 10% 16rem 10%;
    }

    .landing-games--title {
        font-size: 2.8rem;
    }

    .landing-games--paragraph-1 {
        font-size: 1.8rem;
    }

    .landing-games--paragraph-2 {
        font-size: 1.8rem;
    }


    /* News Section */
    .landing-news--button {
        margin: 1rem 0rem 0rem 0rem;
    }


    /* Partners Section */
    
    .landing-partners--title {
        font-size: 2.8rem;
        margin: 0rem 2rem 0rem 2rem;
    }

    .landing-partners--subtitle {
        font-size: 2rem;
        margin-top: 0.5rem;
        margin: 0.5rem 2rem 0rem 2rem;
    }

    .landing-partners--partner-list {
        margin-top: 2rem;
    }

    .landing-partners--paragraph {
        font-size: 1.8rem;
    }


    /* Contact Section */
    .landing-contact--container {
        margin: 0rem 15% 12rem 15%;
    }

}





@media screen
    and (max-width: 680px)
    and (max-height: 500px) {

    /* Intro Section */
    .landing-intro--container {   
        margin: -8rem 10% 16rem 10%;
    }

}



@media screen
    and (max-width: 960px)
    and (orientation: landscape) {

    /* Intro Section */
    .landing-intro--container {   
        margin: -12rem 10% 16rem 10%;
    }

}



@media screen
    and (max-width: 820px)
    and (orientation: landscape) {

    /* Intro Section */
    .landing-intro--container {   
        margin: -8rem 10% 16rem 10%;
    }

}



@media screen
    and (max-width: 680px)
    and (orientation: landscape) {

    /* Intro Section */
    .landing-intro--container {   
        margin: 8rem 10% 16rem 10%;
    }

}

