.btn {
    padding: 0.8rem 2rem;
    border-radius: 0.5rem;
    outline: none;
    border: none;
    cursor: pointer;
}

/* Button Styles */

.btn--primary {
    background-color: var(--primary);
    color: #ffffff;
    border: 0.2rem solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #353535;
    padding: 0.8rem 2rem;
    border: 0.2rem solid var(--primary);
}

.btn--no-outline {
    background-color: transparent;
    color: #353535;
    padding: 0.8rem 2rem;
    border: 0.2rem solid transparent;
}

/* Button Sizes */

.btn--navbar {
    padding: 0.7rem 0.8rem;
    font-size: 1.8rem;
}

.btn--navbar-contact {
    padding: 0.7rem 2.4rem;
    font-size: 1.8rem;
}

.btn--small {
    padding: 0.7rem 2rem;
    font-size: 1.5rem;
}

.btn--medium {
    padding: 0.7rem 2.4rem;
    font-size: 1.8rem;
}

.btn--large {
    padding: 0.7rem 2.8rem;
    font-size: 2.1rem;
}

.btn--x-large {
    padding: 0.7rem 3.2rem;
    font-size: 2.4rem;
}






@media screen 
    and (max-width: 960px)
    and (max-height: 500px)
    and (orientation: landscape) {

    /* Button Sizes */
    .btn--navbar {
        padding: 0rem 0.8rem;
        font-size: 1.8rem;
    }

    .btn--navbar-contact {
        padding: 0rem 2.4rem;
        font-size: 1.8rem;
    }
}



@media screen 
    and (max-width: 960px)
    and (max-height: 350px)
    and (orientation: landscape) {

    /* Button Sizes */
    .btn--navbar {
        padding: 0rem 0.8rem;
        font-size: 1.6rem;
    }

    .btn--navbar-contact {
        padding: 0.4rem 2.4rem;
        font-size: 1.6rem;
    }

}