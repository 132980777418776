.image-card--background {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: #ffffff;
    border: 0.1rem solid #dadada;
    box-shadow: 0.2rem 0.2rem 1rem #999999;
    align-items: center;
}

.background--packages {
    padding: 0.5rem 2rem;
    width: 75%;
}

.background--article {
    padding: 1rem 2rem;
}

.tilt-left {
    transform: rotate(-5deg);
    padding-left: 2rem;
    padding-right: 2rem;
}

.tilt-right {
    transform: rotate(5deg);
    padding-left: 2rem;
    padding-right: 2rem;
}

.image-card--image {
    width: 20rem;
    height: 20rem;
    background-image: url('../assets/Logo.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
    background-position-y: center;
}

.intro-section-card {
    width: 20rem;
    height: 20rem;
}

.games-section-card {
    width: 30rem;
    height: 30rem;
}

.article-card {
    width: 38rem;
    height: 30rem;
}

@media screen and (max-width: 1200px) {
    .games-section-card {
        width:22rem;
        height: 22rem;
    }
}

@media screen and (max-width: 960px) {
    .intro-section-card {
        width: 15rem;
        height: 15rem;
    }

    .games-section-card {
        width:20rem;
        height: 20rem;
    }
}

@media screen and (max-width: 820px) {
    .games-section-card {
        width:18rem;
        height: 18rem;
    }
}

@media screen and (max-width: 680px) {
    .tilt-left {
        transform: none;
        padding: 0rem 1rem 0rem 1rem;
    }
    
    .tilt-right {
        transform: none;
        padding: 0rem 1rem 0rem 1rem;
    }

    .intro-section-card {
        width: 25rem;
        height: 25rem;
    }

    .games-section-card {
        width:20rem;
        height: 20rem;
    }

    .article-card {
        width: 30.4rem;
        height: 24rem;
    }

}



@media screen and (max-width: 400px) {
    .tilt-left {
        transform: none;
        padding: 0rem 1rem 0rem 1rem;
    }
    
    .tilt-right {
        transform: none;
        padding: 0rem 1rem 0rem 1rem;
    }

    .intro-section-card {
        width: 25rem;
        height: 25rem;
    }

    .games-section-card {
        width:20rem;
        height: 20rem;
    }

    .article-card {
        width: 22.8rem;
        height: 18rem;
    }

}
